import { navigate } from 'gatsby-link'
import React from 'react'
import { PageParams } from '../components/organisms/pageFrame'

const ErrorPage = (params: PageParams) => {
  if (typeof window !== 'undefined') {
    navigate('/en')
  }

  return <></>
}

export default ErrorPage
